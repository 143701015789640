import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages
import AboutUs from "views/AboutUs.js";
import Sections from "views/Sections.js";
import MediaBias from "views/MediaBias.js";
import PodCasts from "views/PodCasts.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/media-bias" render={(props) => <MediaBias {...props} />} />
      <Route path="/pod-casts" render={(props) => <PodCasts {...props} />} />
      <Route path="/news" render={(props) => <Sections {...props} />} />
      <Redirect to="/news" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
