import React from "react";
import WhiteNavbar from "components/WhiteNavbar.js";
import Footer from "components/Footer.js";
import {Badge, Card, CardBody, Col, Container, Row} from "reactstrap";

function MediaBias() {
  return (
    <>
      <WhiteNavbar />
      <div className="wrapper">
        <div className="section-space"></div>
        <div className="info info-horizontal">
          <div className="icon icon-primary icon-circle">
            <i className="now-ui-icons media-1_camera-compact"></i>
          </div>
          <div className="description">
            <h2 className="info-title">Media Bias</h2>
          </div>
        </div>
          <Container>
            <Row>
              <Col md="4">
                <Card className="card-pricing">
                  <CardBody>
                    <Badge className="mr-1" color="info">
                      Left
                    </Badge>
                    <ul>
                      <li>CNN</li>
                      <li>MSNBC</li>
                      <li>Huffington Post</li>
                      <li>Associated Press</li>
                      <li>Politico</li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing">
                  <CardBody>
                    <Badge className="mr-1" color="primary">
                      Center
                    </Badge>
                    <ul>
                      <li>The Wall Street Journal</li>
                      <li>Time</li>
                      <li>BBC News</li>
                      <li>ABC News</li>
                      <li>Axios</li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-pricing">
                  <CardBody>
                    <Badge className="mr-1" color="danger">
                      Right
                    </Badge>
                    <ul>
                      <li>Fox News</li>
                      <li>Breitbart News</li>
                      <li>The Hill</li>
                      <li>The Washington Times</li>
                      <li>Newsmax</li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
    </>
  );
}

export default MediaBias;
