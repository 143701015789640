import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import WhiteNavbar from "components/WhiteNavbar.js";
import Footer from "components/Footer.js";

function AboutUs() {
  return (
    <>
      <WhiteNavbar />
      <div className="wrapper">
        <div className="section-space"></div>
              <Container>
                <Col className="ml-auto mr-auto" md="12">
                  <Row>
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons objects_spaceship"></i>
                      </div>
                      <div className="description">
                        <h2 className="info-title">Our Aim</h2>
                        <h4 className="description">
                          Our aim is to push the public to understand the biases in the media and to explore different types of sources. Bias in all forms of media can change the story and leave out crucial information, so we want to make sure everyone can see the full story and interpretations on all sides of the political spectrum before they make a decision that could have been majorly influenced. We hope to bring democracy to its core and bring the two opposing sides closer together.
                        </h4>
                      </div>
                    </div>
                  </Row>
                <Row>
                  <div className="info info-hover">
                    <div className="icon icon-primary icon-circle">
                      <i className="now-ui-icons sport_user-run"></i>
                    </div>
                    <div className="description">
                      <h2 className="info-title">Our Team</h2>
                    </div>
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <Col className="ml-1" md="4">
                    <Card className="card-profile card-plain">
                      <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                              alt="..."
                              className="img img-raised"
                              src={require("assets/img/pavan.png")}
                          ></img>
                        </a>
                      </div>
                      <CardBody>
                        <CardTitle tag="h3">Pavan Kolluru</CardTitle>
                        <h6 className="category">Founder</h6>
                        <CardFooter>
                          <Button
                              className="btn-icon btn-round"
                              color="info"
                              href="https://www.linkedin.com/in/pavankolluru/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin"></i>
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                </Col>
              </Container>
      </div>
        <Footer />
    </>
  );
}

export default AboutUs;
