/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function WhiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img
                  alt="..."
                  className="creative-tim-logo"
                  src={require("assets/img/tu_logo.png")}
              ></img>
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              Think Unbiased
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
            <NavItem>
              <NavLink href="/news">
                <i className="now-ui-icons media-2_sound-wave"></i>
                <p>News</p>
              </NavLink>
            </NavItem>
              <NavItem>
                <NavLink href="/pod-casts">
                  <i className="now-ui-icons tech_headphones"></i>
                  <p>Podcasts</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/media-bias">
                  <i className="now-ui-icons media-1_camera-compact"></i>
                  <p>Media Bias</p>
                </NavLink>
              </NavItem>
            <NavItem>
              <NavLink href="/about-us">
                <i className="now-ui-icons objects_spaceship"></i>
                <p>About Us</p>
              </NavLink>
            </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
