import React from "react";
import WhiteNavbar from "components/WhiteNavbar.js";
import Footer from "components/Footer.js";
import {
    Alert,
    Card,
    CardTitle,
    Col,
    Collapse,
    Container,
    Row
} from "reactstrap";

function PodCasts() {
    //collapse states and functions
    const [collapses, setCollapses] = React.useState([0]);
    const changeCollapse = (collapse) => {
        if (collapses.includes(collapse)) {
            setCollapses(collapses.filter((prop) => prop !== collapse));
        } else {
            setCollapses([...collapses, collapse]);
        }
    };
    return (
        <>
            <WhiteNavbar/>
            <div className="wrapper">
                <div className="section-space"></div>
                    <div className="info info-horizontal">
                        <div className="icon icon-primary icon-circle">
                            <i className="now-ui-icons tech_headphones"></i>
                        </div>
                        <div className="description">
                            <h2 className="info-title">Podcasts</h2>
                        </div>
                    </div>
                            <Container>
                                <Col className="ml-auto mr-auto" md="12">
                                    <Row>
                                        <Col md="4">
                                            <div class="essential">
                                                <Alert color="info">
                                                    <strong>From The Left</strong>
                                                </Alert>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='1Y3colnIhnNfvBcK8O9y7p'
                                                            src="https://open.spotify.com/embed-podcast/show/1Y3colnIhnNfvBcK8O9y7p"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/1Y3colnIhnNfvBcK8O9y7p"
                                                           target="_blank" rel="noopener noreferrer">
                                                            Pod Save the People
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} Crooked
                                                        Media
                                                    </div>
                                                </Card>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='5JGorGvdwljJHTl6wpMXN3'
                                                            src="https://open.spotify.com/embed-podcast/show/5JGorGvdwljJHTl6wpMXN3"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/5JGorGvdwljJHTl6wpMXN3"
                                                           target="_blank" rel="noopener noreferrer">
                                                            Pod Save America
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} Crooked
                                                        Media
                                                    </div>
                                                </Card>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='2noCXQGBJp76DR2qcxETPy'
                                                            src="https://open.spotify.com/embed-podcast/show/2noCXQGBJp76DR2qcxETPy"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/2noCXQGBJp76DR2qcxETPy"
                                                           target="_blank" rel="noopener noreferrer">
                                                            The Dig
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} Daniel
                                                        Denvir, Jacobin
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="optional">
                                                <Alert color="info">
                                                    <a aria-expanded={collapses.includes(1)}
                                                       data-parent="#accordion"
                                                       data-toggle="collapse"
                                                       href="#pablo"
                                                       className="text-light"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           changeCollapse(1);
                                                       }}>
                                                        <strong>From The Left</strong>
                                                        <span className="float-right">
                          <i className="now-ui-icons arrows-1_minimal-down"></i>
                        </span>
                                                    </a>
                                                </Alert>
                                                <Collapse isOpen={collapses.includes(1)}>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='1Y3colnIhnNfvBcK8O9y7p'
                                                                src="https://open.spotify.com/embed-podcast/show/1Y3colnIhnNfvBcK8O9y7p"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/1Y3colnIhnNfvBcK8O9y7p"
                                                               target="_blank" rel="noopener noreferrer">
                                                                Pod Save the People
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} Crooked
                                                            Media
                                                        </div>
                                                    </Card>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='5JGorGvdwljJHTl6wpMXN3'
                                                                src="https://open.spotify.com/embed-podcast/show/5JGorGvdwljJHTl6wpMXN3"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/5JGorGvdwljJHTl6wpMXN3"
                                                               target="_blank" rel="noopener noreferrer">
                                                                Pod Save America
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} Crooked
                                                            Media
                                                        </div>
                                                    </Card>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='2noCXQGBJp76DR2qcxETPy'
                                                                src="https://open.spotify.com/embed-podcast/show/2noCXQGBJp76DR2qcxETPy"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/2noCXQGBJp76DR2qcxETPy"
                                                               target="_blank" rel="noopener noreferrer">
                                                                The Dig
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} Daniel
                                                            Denvir, Jacobin
                                                        </div>
                                                    </Card>
                                                </Collapse>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="essential">
                                                <Alert color="secondary">
                                                    <strong>From The Center</strong>
                                                </Alert>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='21X49oNQyL8c6GKmgzjnmo'
                                                            src="https://open.spotify.com/embed-podcast/show/21X49oNQyL8c6GKmgzjnmo"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/21X49oNQyL8c6GKmgzjnmo"
                                                           target="_blank" rel="noopener noreferrer">
                                                            Left, Right & Center
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} KCRW
                                                        Media
                                                    </div>
                                                </Card>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='3pXx5SXzXwJxnf4A5pWN2A'
                                                            src="https://open.spotify.com/embed-podcast/show/3pXx5SXzXwJxnf4A5pWN2A"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/3pXx5SXzXwJxnf4A5pWN2A"
                                                           target="_blank" rel="noopener noreferrer">
                                                            Today, Explained
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} Vox
                                                    </div>
                                                </Card>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='6bmhSFLKtApYClEuSH8q42'
                                                            src="https://open.spotify.com/embed-podcast/show/6bmhSFLKtApYClEuSH8q42"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/6bmhSFLKtApYClEuSH8q42"
                                                           target="_blank" rel="noopener noreferrer">
                                                            The Argument
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} New York
                                                        Times Opinion
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="optional">
                                                <Alert color="secondary">
                                                    <a aria-expanded={collapses.includes(2)}
                                                       data-parent="#accordion"
                                                       data-toggle="collapse"
                                                       href="#pablo"
                                                       className="text-light"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           changeCollapse(2);
                                                       }}>
                                                        <strong>From The Center</strong>
                                                        <span className="float-right">
                          <i className="now-ui-icons arrows-1_minimal-down"></i>
                        </span>
                                                    </a>
                                                </Alert>
                                                <Collapse isOpen={collapses.includes(2)}>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='21X49oNQyL8c6GKmgzjnmo'
                                                                src="https://open.spotify.com/embed-podcast/show/21X49oNQyL8c6GKmgzjnmo"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/21X49oNQyL8c6GKmgzjnmo"
                                                               target="_blank" rel="noopener noreferrer">
                                                                Left, Right & Center
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} KCRW
                                                            Media
                                                        </div>
                                                    </Card>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='3pXx5SXzXwJxnf4A5pWN2A'
                                                                src="https://open.spotify.com/embed-podcast/show/3pXx5SXzXwJxnf4A5pWN2A"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/3pXx5SXzXwJxnf4A5pWN2A"
                                                               target="_blank" rel="noopener noreferrer">
                                                                Today, Explained
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} Vox
                                                        </div>
                                                    </Card>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='6bmhSFLKtApYClEuSH8q42'
                                                                src="https://open.spotify.com/embed-podcast/show/6bmhSFLKtApYClEuSH8q42"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/6bmhSFLKtApYClEuSH8q42"
                                                               target="_blank" rel="noopener noreferrer">
                                                                The Argument
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} New
                                                            York Times Opinion
                                                        </div>
                                                    </Card>
                                                </Collapse>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="essential">
                                                <Alert color="danger">
                                                    <strong>From The Right</strong>
                                                </Alert>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='1WErgoXiZwgctkHLzqU6nf'
                                                            src="https://open.spotify.com/embed-podcast/show/1WErgoXiZwgctkHLzqU6nf"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/1WErgoXiZwgctkHLzqU6nf"
                                                           target="_blank" rel="noopener noreferrer">
                                                            The Ben Shapiro Show
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} The Daily
                                                        Wire

                                                        Media
                                                    </div>
                                                </Card>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='030JakQMatQTMOIkwVx2cQ'
                                                            src="https://open.spotify.com/embed-podcast/show/030JakQMatQTMOIkwVx2cQ"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/030JakQMatQTMOIkwVx2cQ"
                                                           target="_blank" rel="noopener noreferrer">
                                                            Verdict with Ted Cruz
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} Ted Cruz
                                                    </div>
                                                </Card>
                                                <Card className="card-plain card-blog">
                                                    <div className="card-image">
                                                        <iframe title='40i5oPvXCmqYrnVZ9PfOMh'
                                                            src="https://open.spotify.com/embed-podcast/show/40i5oPvXCmqYrnVZ9PfOMh"
                                                            width="100%" height="232" frameBorder="0"
                                                            allowTransparency="true"
                                                            allow="encrypted-media"></iframe>
                                                    </div>
                                                    <CardTitle tag="h3">
                                                        <a href="https://open.spotify.com/show/40i5oPvXCmqYrnVZ9PfOMh"
                                                           target="_blank" rel="noopener noreferrer">
                                                            Candace Owens Show
                                                        </a>
                                                    </CardTitle>
                                                    <div className="stats">
                                                        <i className="now-ui-icons users_single-02"></i>{' '} PragerU
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="optional">
                                                <Alert color="danger">
                                                    <a aria-expanded={collapses.includes(3)}
                                                       data-parent="#accordion"
                                                       data-toggle="collapse"
                                                       href="#pablo"
                                                       className="text-light"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           changeCollapse(3);
                                                       }}>
                                                        <strong>From The Right</strong>
                                                        <span className="float-right">
                          <i className="now-ui-icons arrows-1_minimal-down"></i>
                        </span>
                                                    </a>
                                                </Alert>
                                                <Collapse isOpen={collapses.includes(3)}>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='1WErgoXiZwgctkHLzqU6nf'
                                                                src="https://open.spotify.com/embed-podcast/show/1WErgoXiZwgctkHLzqU6nf"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/1WErgoXiZwgctkHLzqU6nf"
                                                               target="_blank" rel="noopener noreferrer">
                                                                The Ben Shapiro Show
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} The
                                                            Daily Wire

                                                            Media
                                                        </div>
                                                    </Card>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='030JakQMatQTMOIkwVx2cQ'
                                                                src="https://open.spotify.com/embed-podcast/show/030JakQMatQTMOIkwVx2cQ"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/030JakQMatQTMOIkwVx2cQ"
                                                               target="_blank" rel="noopener noreferrer">
                                                                Verdict with Ted Cruz
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} Ted
                                                            Cruz
                                                        </div>
                                                    </Card>
                                                    <Card className="card-plain card-blog">
                                                        <div className="card-image">
                                                            <iframe title='40i5oPvXCmqYrnVZ9PfOMh'
                                                                src="https://open.spotify.com/embed-podcast/show/40i5oPvXCmqYrnVZ9PfOMh"
                                                                width="100%" height="232" frameBorder="0"
                                                                allowTransparency="true"
                                                                allow="encrypted-media"></iframe>
                                                        </div>
                                                        <CardTitle tag="h3">
                                                            <a href="https://open.spotify.com/show/40i5oPvXCmqYrnVZ9PfOMh"
                                                               target="_blank" rel="noopener noreferrer">
                                                               Candace Owens Show
                                                            </a>
                                                        </CardTitle>
                                                        <div className="stats">
                                                            <i className="now-ui-icons users_single-02"></i>{' '} PragerU
                                                        </div>
                                                    </Card>
                                                </Collapse>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Container>
                        </div>
            <Footer/>
        </>
    );
}

export default PodCasts;
