/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <div className="optional">
          <nav>
            <ul>
              <li>
                <a
                    href="/news"
                >
                  <i className="now-ui-icons media-2_sound-wave"></i>
                  <span className="ml-1">News</span>
                </a>
              </li>
              <li>
                <a
                    href="/pod-casts"
                >
                  <i className="now-ui-icons tech_headphones"></i>
                  <span className="ml-1">Podcasts</span>
                </a>
              </li>
              <li>
                <a
                    href="/media-bias"
                >
                  <i className="now-ui-icons media-1_camera-compact"></i>
                  <span className="ml-1">Media Bias</span>
                </a>
              </li>
              <li>
                <a
                    href="/about-us"
                >
                  <i className="now-ui-icons objects_spaceship"></i>
                  <span className="ml-1">About Us</span>
                </a>
              </li>
            </ul>
          </nav>
          </div>
          <div className="essential">
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Think{" "}
            <a
              href="/news"
            >
              Unbiased
            </a>
            .
          </div>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
