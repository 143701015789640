import React from "react";
import ta from "time-ago";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Alert,
  Collapse
} from "reactstrap";

function News() {
  //collapse states and functions
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  const [headlines, setHeadlines] = React.useState({left:[], center:[], right:[]});
  React.useEffect(() => {
   fetch('https://shv9a9m5yh.execute-api.us-east-1.amazonaws.com/dev/news')
     //fetch('http://localhost:3000/dev/news')
        .then(response => response.json())
        .then(data => setHeadlines(data));

  }, []);
  return (
    <>
          <div className="info info-horizontal">
            <div className="icon icon-primary icon-circle">
              <i className="now-ui-icons media-2_sound-wave"></i>
            </div>
            <div className="description">
              <h2 className="info-title">Trending</h2>
            </div>
          </div>
          <Container>
              <Col className="ml-auto mr-auto" md="12">
                <Row>
                  <Col md="4">
                    <div className="essential">
                      <Alert color="info">
                        <strong>From The Left</strong>
                      </Alert>
                      {headlines.left.map(item =>
                          <Card className="card-blog">
                            <div className="card-image">
                                <img
                                    alt="..."
                                    className="img-news rounded"
                                    src={item.urlToImage}
                                ></img>
                            </div>
                            <CardBody>
                              <h6 className="category">{item.source.name}</h6>
                              <div className="titleDesc-fixed-height">
                                <CardTitle tag="h5" className="title-overflow-ellipse">
                                  {item.title}
                                </CardTitle>
                                <p className="card-description desc-overflow-ellipse">
                                  {item.description}
                                </p>
                              </div>
                              <a href={item.url} target="_blank" className="stretched-link" rel="noopener noreferrer"> </a>
                            <CardFooter>
                                <div className="stats">
                                  <i className="now-ui-icons tech_watch-time"></i>{' '}{ta.ago(item.publishedAt)}
                                </div>
                                <div className="stats stats-right">
                                  <i className="now-ui-icons education_glasses"></i>{' '}{item.timeToRead}
                                </div>
                              </CardFooter>
                            </CardBody>
                          </Card>
                      )}
                    </div>
                    <div className="optional">
                      <Alert color="info">
                        <a aria-expanded={collapses.includes(1)}
                           data-parent="#accordion"
                           data-toggle="collapse"
                           href="#pablo"
                           className="text-light"
                           onClick={(e) => {
                             e.preventDefault();
                             changeCollapse(1);
                           }} >
                          <strong>From The Left</strong>
                        <span className="float-right">
                          <i className="now-ui-icons arrows-1_minimal-down"></i>
                        </span>
                      </a>
                      </Alert>
                      <Collapse isOpen={collapses.includes(1)}>
                        {headlines.left.map(item =>
                            <Card className="card-blog">
                              <div className="card-image">
                                  <img
                                      alt="..."
                                      className="img-news rounded"
                                      src={item.urlToImage}
                                  ></img>
                              </div>
                              <CardBody>
                                <h6 className="category">{item.source.name}</h6>
                                <div className="titleDesc-fixed-height">
                                  <CardTitle tag="h5" className="title-overflow-ellipse">
                                    {item.title}
                                  </CardTitle>
                                  <p className="card-description desc-overflow-ellipse">
                                    {item.description}
                                  </p>
                                </div>
                                <a href={item.url} target="_blank" className="stretched-link" rel="noopener noreferrer"> </a>
                                <CardFooter>
                                  <div className="stats">
                                    <i className="now-ui-icons tech_watch-time"></i>{' '}{ta.ago(item.publishedAt)}
                                  </div>
                                  <div className="stats stats-right">
                                    <i className="now-ui-icons education_glasses"></i>{' '}{item.timeToRead}
                                  </div>
                                </CardFooter>
                              </CardBody>
                            </Card>
                        )}
                      </Collapse>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="essential">
                      <Alert color="secondary">
                        <strong>From The Center</strong>
                      </Alert>
                      {headlines.center.map(item =>
                          <Card className="card-blog">
                            <div className="card-image">
                                <img
                                    alt="..."
                                    className="img-news rounded"
                                    src={item.urlToImage}
                                ></img>
                            </div>
                            <CardBody>
                              <h6 className="category">{item.source.name}</h6>
                              <div className="titleDesc-fixed-height">
                                <CardTitle tag="h5" className="title-overflow-ellipse">
                                  {item.title}
                                </CardTitle>
                                <p className="card-description desc-overflow-ellipse">
                                  {item.description}
                                </p>
                              </div>
                              <a href={item.url} target="_blank" className="stretched-link" rel="noopener noreferrer"> </a>
                              <CardFooter>
                                <div className="stats">
                                  <i className="now-ui-icons tech_watch-time"></i>{' '}{ta.ago(item.publishedAt)}
                                </div>
                                <div className="stats stats-right">
                                  <i className="now-ui-icons education_glasses"></i>{' '}{item.timeToRead}
                                </div>
                              </CardFooter>
                            </CardBody>
                          </Card>
                      )}
                    </div>
                    <div className="optional">
                      <Alert color="secondary">
                        <a aria-expanded={collapses.includes(2)}
                           data-parent="#accordion"
                           data-toggle="collapse"
                           href="#pablo"
                           className="text-light"
                           onClick={(e) => {
                             e.preventDefault();
                             changeCollapse(2);
                           }}>
                          <strong>From The Center</strong>
                        <span className="float-right">
                          <i className="now-ui-icons arrows-1_minimal-down"></i>
                        </span>
                      </a>
                      </Alert>
                      <Collapse isOpen={collapses.includes(2)}>
                        {headlines.center.map(item =>
                            <Card className="card-blog">
                              <div className="card-image">
                                  <img
                                      alt="..."
                                      className="img-news rounded"
                                      src={item.urlToImage}
                                  ></img>
                              </div>
                              <CardBody>
                                <h6 className="category">{item.source.name}</h6>
                                <div className="titleDesc-fixed-height">
                                  <CardTitle tag="h5" className="title-overflow-ellipse">
                                    {item.title}
                                  </CardTitle>
                                  <p className="card-description desc-overflow-ellipse">
                                    {item.description}
                                  </p>
                                </div>
                                <a href={item.url} target="_blank" className="stretched-link" rel="noopener noreferrer"> </a>
                                <CardFooter>
                                  <div className="stats">
                                    <i className="now-ui-icons tech_watch-time"></i>{' '}{ta.ago(item.publishedAt)}
                                  </div>
                                  <div className="stats stats-right">
                                    <i className="now-ui-icons education_glasses"></i>{' '}{item.timeToRead}
                                  </div>
                                </CardFooter>
                              </CardBody>
                            </Card>
                        )}
                      </Collapse>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="essential">
                      <Alert color="danger">
                        <strong>From The Right</strong>
                      </Alert>
                      {headlines.right.map(item =>
                          <Card className="card-blog">
                            <div className="card-image">
                                <img
                                    alt="..."
                                    className="img-news rounded"
                                    src={item.urlToImage}
                                ></img>
                            </div>
                            <CardBody>
                              <h6 className="category">{item.source.name}</h6>
                              <div className="titleDesc-fixed-height">
                                <CardTitle tag="h5" className="title-overflow-ellipse">
                                  {item.title}
                                </CardTitle>
                                <p className="card-description desc-overflow-ellipse">
                                  {item.description}
                                </p>
                              </div>
                              <a href={item.url} target="_blank" className="stretched-link" rel="noopener noreferrer"> </a>
                              <CardFooter>
                                <div className="stats">
                                  <i className="now-ui-icons tech_watch-time"></i>{' '}{ta.ago(item.publishedAt)}
                                </div>
                                <div className="stats stats-right">
                                  <i className="now-ui-icons education_glasses"></i>{' '}{item.timeToRead}
                                </div>
                              </CardFooter>
                            </CardBody>
                          </Card>
                      )}
                    </div>
                    <div className="optional">
                      <Alert color="danger">
                        <a aria-expanded={collapses.includes(3)}
                           data-parent="#accordion"
                           data-toggle="collapse"
                           href="#pablo"
                           className="text-light"
                           onClick={(e) => {
                             e.preventDefault();
                             changeCollapse(3);
                           }} >
                          <strong>From The Right</strong>
                        <span className="float-right">
                          <i className="now-ui-icons arrows-1_minimal-down"></i>
                        </span>
                      </a>
                      </Alert>
                      <Collapse isOpen={collapses.includes(3)}>
                        {headlines.right.map(item =>
                            <Card className="card-blog">
                              <div className="card-image">
                                  <img
                                      alt="..."
                                      className="img-news rounded"
                                      src={item.urlToImage}
                                  ></img>
                              </div>
                              <CardBody>
                                <h6 className="category">{item.source.name}</h6>
                                <div className="titleDesc-fixed-height">
                                  <CardTitle tag="h5" className="title-overflow-ellipse">
                                    {item.title}
                                  </CardTitle>
                                  <p className="card-description desc-overflow-ellipse">
                                    {item.description}
                                  </p>
                                </div>
                                <a href={item.url} target="_blank" className="stretched-link" rel="noopener noreferrer"> </a>
                                <CardFooter>
                                  <div className="stats">
                                    <i className="now-ui-icons tech_watch-time"></i>{' '}{ta.ago(item.publishedAt)}
                                  </div>
                                  <div className="stats stats-right">
                                    <i className="now-ui-icons education_glasses"></i>{' '}{item.timeToRead}
                                  </div>
                                </CardFooter>
                              </CardBody>
                            </Card>
                        )}
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              </Col>
          </Container>
    </>
  );
}

export default News;
